<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>

        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>

      <div v-if="is_API_loaded" class="space-y-10">
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">自訂銀行轉帳</h3>
            <p>設定您的銀行轉帳資訊，供顧客選擇。</p>
          </div>
          <div class="rightBlock">
            <ul>
              <li class="flex items-center justify-between">
                <p>自訂銀行轉帳</p>
                <router-link :to="{ name: 'bank_transfer' }">
                  <vs-button transparent color="success" size="large"> 編輯 </vs-button>
                </router-link>
              </li>
            </ul>
          </div>
        </section>
        <hr />
        <section class="wrap">
          <div class="leftBlock">
            <h3 class="h3 sm:mt-4">第三方金流串接</h3>
            <p>我們提供多種第三方金流服務來幫助您更有效率的收取款項。</p>
          </div>
          <div class="rightBlock">
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <router-link
                :to="{ name: item.routerName }"
                v-for="(item, index) in payments_img"
                :key="index"
                class="
                  flex flex-col
                  items-center
                  justify-between
                  p-4
                  border-2
                  rounded-xl
                  border-gray-background
                  shadow-xl
                  hover:shadow-none
                  transition-all
                  duration-300
                  transform
                  hover:translate-y-1
                "
              >
                <div class="flex items-center justify-center flex-grow mb-2">
                  <img :src="item.img" :alt="item.title" />
                </div>
                <p>{{ item.title }}</p>
              </router-link>
            </div>
          </div>
        </section>
      </div>

      <!-- 骨架屏 -->
      <!-- <div class="space-y-4" v-else>
          <section v-for="(item, index) in 2" :key="index" class="wrap">
            <div class="leftBlock">
              <skeleton class="mt-4" width="100px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
              <skeleton width="200px" height="10px" />
            </div>
            <div class="rightBlock">
              <div class="flex items-center justify-between">
                <skeleton width="100px" />
                <skeleton width="100px" />
              </div>
              <div class="flex items-start flex-col space-y-2">
                <skeleton v-for="(item, index) in 3" :key="index" width="200px" />
              </div>
            </div>
          </section>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'store_payments',
  data() {
    return {
      is_API_loaded: true,
      paymentBankTransfer: {},
      payments_img: [
        // {
        //   img: require('@/assets/images/payments/ecpay_logo.png'),
        //   title: '自訂收款方式',
        // },
        {
          img: require('@/assets/images/payments/ecpay_logo.png'),
          title: '綠界科技',
          routerName: 'ecpay-payment',
        },
        {
          img: require('@/assets/images/payments/newebpay_logo.png'),
          title: '藍新科技',
          routerName: 'newebpay-payment',
        },
      ],
    }
  },
  created() {
    // this.loadPayment1()
  },
  methods: {
    // 4.4.1.1 金流資料-讀取
    loadPayment1() {
      this.$axios({
        url: 'front/set/app/getGoldData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.paymentBankTransfer = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap {
  @apply flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0;
}

.leftBlock {
  @apply w-full sm:w-1/3 space-y-4 flex-shrink-0;
}

.rightBlock {
  @apply flex-grow h-fit bg-white p-4 rounded-xl space-y-4;
}
// .checkbox_section {
//   @apply w-28 flex-shrink-0;
// }

// .title_section {
//   // @apply flex items-center w-min sm:w-full;
//   // .title {
//   //   @apply w-48 sm:flex-grow;
//   // }
//   @apply grid gap-4
//   w-full text-left;
//   grid-template-columns: 100px 150px repeat(3, 100px);
//   @screen md {
//     @apply grid-cols-5;
//   }
// }
</style>
